body.tv-page {
    margin-top: 200px;
    /* color: #fff !important; */
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    background-color: transparent !important;
    overflow: hidden;
}

.center_div{
    font-family: Roboto,"Helvetica","Arial",sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    /* height: 80vh; */
}
.center_div * {
    font-family: Noto Sans Hebrew
}

.left_side{
    width: 100%;
    height: 90vh;

}
.text-area{
    width: 100%;
    height: 100%;
resize: none;
    padding: 10px;
    outline: none;
    /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
    border: none;
}
.right_side{
    width: 100%;
        height: 90vh;
    overflow-y: auto;
    padding: 10px;
    /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
}